<div >
  <iframe [src]="url | safe" id="frame" name="frame" [ngClass]="{'iframefull': !this.inputHost, 'iframepopup': this.inputHost}">

  </iframe>

  <form   target="frame" [action]="url" #form method="POST"  >
    <input type="hidden" name="token" [value]="a_field" id="token" /> 
  </form>

  
</div>
 